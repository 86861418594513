import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/LayoutComponent"
import { Container, Card, Button, Row, Col } from "react-bootstrap"
import netlifyStyle from "../../site/settings/style.json"
import SEO from "../components/seo"

const ProjectGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      pageContent: allFile(filter: { relativeDirectory: { eq: "projects" } }) {
        edges {
          node {
            absolutePath
            relativeDirectory
            childMarkdownRemark {
              frontmatter {
                location
                title
                date
                description
                featuredimage
                contractor
                completion_date
              }
              fields {
                slug
              }
              rawMarkdownBody
            }
          }
        }
      }

      projectLandingPage: allFile(
        filter: { base: { eq: "project-gallery.md" } }
      ) {
        edges {
          node {
            absolutePath
            relativeDirectory
            childMarkdownRemark {
              frontmatter {
                background
                heading
                intro
                date
              }
            }
          }
        }
      }
    }
  `)

  const containerStyles = {
    minHeight: "100vh",
    marginTop: "2rem",
  }

  const cardOverlayStyle = {
    background: "rgba(78, 78, 78, 0.651)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }

  const cardStyle = {
    background: "none",
    maxHeight: "18.1rem",
  }

  const mainHeadingFont = {
    color: netlifyStyle.secondary_font_color,
  }

  const backgroundContainerStyles = {
    minHeight: "40vh",
    maxWidth: "100vw",
    backgroundColor: "rgba(78, 78, 78, 0.651)",
    margin: 0,
  }

  const pageData =
    data.projectLandingPage.edges[0].node.childMarkdownRemark.frontmatter

  const [state, setState] = useState({
    jobData: [],
  })

  function Job(name, contractor, location, completionDate, publishDate) {
    this.name = name
    this.contractor = contractor
    this.location = location
    this.completionDate = completionDate
    this.publishDate = publishDate
  }

  const getJobsData = () => {
    data.pageContent.edges.forEach(edge => {
      const currentJob = new Job(
        edge.node.childMarkdownRemark.frontmatter.title,
        edge.node.childMarkdownRemark.frontmatter.contractor,
        edge.node.childMarkdownRemark.frontmatter.location,
        edge.node.childMarkdownRemark.frontmatter.completion_date,
        edge.node.childMarkdownRemark.frontmatter.date
      )

      state.jobData.push(currentJob)
    })
  }

  useEffect(() => {
    getJobsData()
  }, [])

  return (
    <Layout>
      <SEO
        title="DNA Project Gallery"
        description="DNA has completed a wide variety of Commercial and Residential HVAC projects. We can handle any special project you throw at us"
        keywords="HVAC, Las Vegas, Project Gallery, Specialty Contractor, Commercial, Residential, Mechanical Contractor"
      />
      <div
        style={{
          background: `url("${pageData.background}") no-repeat center center/cover`,
        }}
        className="mb-3"
      >
        <Container
          style={backgroundContainerStyles}
          className="d-flex align-items-center"
        >
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="align-items-center d-flex justify-content-center flex-column w-100 h-100"
          >
            <h1 style={mainHeadingFont}>{pageData.heading}</h1>
            <h3 className="text-center" style={mainHeadingFont}>
              {pageData.intro}
            </h3>
          </div>
        </Container>
      </div>
      <Container style={containerStyles}>
        <Row>
          {data.pageContent.edges.map(edge => {
            return (
              <Col className="mb-lg-4" xs={12} md={4}>
                <Link
                  to={`/project-gallery/${edge.node.childMarkdownRemark.fields.slug}`}
                >
                  <Card className="text-white" style={cardStyle}>
                    <Card.Img
                      style={{ height: "18rem" }}
                      src={
                        edge.node.childMarkdownRemark.frontmatter.featuredimage
                      }
                    />

                    <Card.ImgOverlay style={cardOverlayStyle}>
                      <Card.Title>
                        {edge.node.childMarkdownRemark.frontmatter.title}
                      </Card.Title>
                      <Card.Subtitle>
                        {" "}
                        {edge.node.childMarkdownRemark.frontmatter.location}
                      </Card.Subtitle>
                      <Card.Text className="mt-3">
                        {edge.node.childMarkdownRemark.frontmatter.description}
                      </Card.Text>
                      <Card.Text className="mt-5">
                        {" "}
                        Published:{" "}
                        {String(
                          edge.node.childMarkdownRemark.frontmatter.date
                        ).slice(0, 10)}
                      </Card.Text>
                      <Card.Text style={{}} className="font-weight-bold">
                        Click to view project details{" "}
                        <FontAwesomeIcon
                          style={{ color: netlifyStyle.brand_color }}
                          icon={faChevronRight}
                        />
                      </Card.Text>
                    </Card.ImgOverlay>
                  </Card>
                </Link>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

/* <Image className={"card-img"} markdownLink={edge.node.childMarkdownRemark.frontmatter.featuredimage}/>*/

/*  
border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px);
*/
export default ProjectGallery
